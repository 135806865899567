import { motion } from 'framer-motion';
import Image from 'next/image';

import {
  ChartIcon,
  CheckListIcon,
  FeatureIcon,
  ThunderIcon,
  UtilitiesImage,
} from '@/assets';
import { Title } from '@/components/common/Title';
import type { UtilityProps } from '@/components/common/Utility';
import { Utility } from '@/components/common/Utility';

const utilities: UtilityProps[] = [
  {
    icon: <Image src={ChartIcon} alt="" />,
    title: 'Cung cấp thông tin và phân tích nhu cầu',
    description:
      'Cung cấp thông tin chi tiết và phân tích sâu sắc giúp hiểu rõ hơn về nhu cầu của khách hàng và điều chỉnh chiến lược kinh doanh một cách linh hoạt và hiệu quả.',
  },
  {
    icon: <Image src={ThunderIcon} alt="" />,
    title: 'Tối ưu hóa',
    description:
      'Tối ưu hoá mọi khía cạnh trong hoạt động kinh doanh, bao gồm quản lý đơn hàng, thực đơn, nhân viên và thống kê hiệu suất',
  },
  {
    icon: <Image src={FeatureIcon} alt="" />,
    title: 'Dịch vụ chuyên nghiệp và tiện lợi',
    description:
      'Dịch vụ chuyên nghiệp và tiện lợi của Hàng Rong đảm bảo trải nghiệm tốt nhất cho khách hàng.',
  },
  {
    icon: <Image src={CheckListIcon} alt="" />,
    title: 'Công cụ và tính năng tiên tiến',
    description:
      'Hàng Rong cung cấp các công cụ và tính năng tiên tiến giúp doanh nghiệp nắm bắt được xu hướng và nhu cầu của thị trường',
  },
];

export const UtilitiesSection = () => {
  return (
    <section
      id="utilities"
      className="m-auto my-24 px-6 lg:container lg:max-w-[1440px] lg:px-28"
    >
      <Title
        title="Lợi ích khi sử dụng giải pháp"
        description="Giải pháp quản lý quán ăn, cửa hàng đơn giản, hiệu quả, đáp ứng mọi nhu cầu, được tin dùng bởi hơn 4.000 cửa hàng."
        className="m-auto max-w-3xl text-center"
      />
      <div className="mt-16 flex flex-col overflow-hidden md:flex-row md:justify-between">
        <motion.div
          className="md:w-72"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          {utilities.slice(0, 2).map((utility, key) => (
            <Utility
              icon={utility.icon}
              title={utility.title}
              description={utility.description}
              key={key}
              className="mt-10 flex w-full flex-col items-center text-center md:items-start md:text-start md:first:mt-0"
              variants={{
                offscreen: { x: -300, opacity: 0 },
                onscreen: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: 'spring',
                    duration: 0.7 + key / 3,
                  },
                },
              }}
            />
          ))}
        </motion.div>
        <div className="order-first mt-20 md:order-none md:mt-0">
          <Image
            src={UtilitiesImage}
            alt=""
            className="m-auto object-contain"
          />
        </div>
        <motion.div
          className="md:w-72"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          {utilities.slice(2).map((utility, key) => (
            <Utility
              icon={utility.icon}
              title={utility.title}
              description={utility.description}
              key={key}
              className="mt-10 flex w-full flex-col items-center text-center md:items-start md:text-start md:first:mt-0"
              variants={{
                offscreen: { x: 300, opacity: 0 },
                onscreen: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    type: 'spring',
                    delay: 0.5,
                    duration: 0.7 + key / 3,
                  },
                },
              }}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
};
