import Image from 'next/image';

import { ExpressIcon, FeatureManageImage, MessageIcon } from '@/assets';
import type { FeatureProps } from '@/components/common/Feature';
import { Feature } from '@/components/common/Feature';
import { Title } from '@/components/common/Title';

const featuresData: FeatureProps[] = [
  {
    icon: <Image src={MessageIcon} alt="" />,
    title: 'Quản lý đơn hàng',
    description: `Giải pháp quản lý được thiết kế dành riêng cho Doanh nghiệp/Cửa hàng vừa và nhỏ có nhu 
    cầu số hoá và thống kê số lượng đơn hàng, nguồn tiền và xu hướng các món ăn tại cửa hàng`,
    image: <Image src={FeatureManageImage} alt="" />,
    features: [
      {
        item: 'Quy trình xử lý đơn',
        sublist: [
          'Xử lý đơn hàng nhanh chóng và hiệu quả, tăng năng suất làm việc',
          'Giảm thiểu sai sót giảm thiểu rủi ro sai sót và nhầm lẫn khi xử lý đơn hàng',
        ],
      },
      {
        item: 'Quy trình tính tiền và tổng hợp doanh thu',
        sublist: [
          'Tự động hóa quá trình xử lý đơn hàng, tính năng này giảm thiểu rủi ro sai sót và nhầm lẫn.',
          'Nâng cao trải nghiệm khách hàng, đảm bảo đơn hàng được xử lý nhanh chóng và chính xác.',
        ],
      },
    ],
  },
  {
    icon: <Image src={ExpressIcon} alt="" />,
    title: 'Thống kê',
    description: `Giải pháp giúp người quản lý hiểu rõ hơn về hoạt động kinh doanh, cung cấp cái nhìn 
    tổng quan về hiệu suất kinh doanh, giúp người dùng đánh giá được doanh số bán hàng, lợi nhuận và khách hàng đến quán.`,
    image: <Image src={FeatureManageImage} alt="" />,
    features: [
      {
        item: 'Định hình chiến lược kinh doanh dựa trên dữ liệu thống kê, xây dựng được chiến lược kinh doanh phù hợp.',
      },
      {
        item: `Tối ưu hóa chi phí và nguồn lực, giúp xác định những nguồn lực đang được sử dụng không hiệu quả, tiết 
        kiệm chi phí và tăng cường hiệu suất làm việc.`,
      },
    ],
  },
];

export const FeaturesSection = () => {
  return (
    <section
      id="features"
      className="relative m-auto mt-48 scroll-mt-20 px-6 lg:container lg:max-w-[1440px] lg:px-0"
    >
      <Title
        tag="Tính năng nổi bật"
        title="Các tính năng nổi bật của Hàng Rong"
        className="m-auto w-fit text-center"
      />
      <div className="mt-24 w-full">
        {featuresData.map((features, key) => (
          <Feature
            {...features}
            key={key}
            variant={key % 2 === 0 ? 'origin' : 'reverse'}
          />
        ))}
      </div>
    </section>
  );
};
