import StarIconOutline from '@heroicons/react/24/outline/StarIcon';
import StarIconSolid from '@heroicons/react/24/solid/StarIcon';
import type { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import React from 'react';

export interface CardTestimionialProps {
  point: 0 | 1 | 2 | 3 | 4 | 5;
  review: string;
  avatar: StaticImport;
  name: string;
  role: string;
}

export const CardTestimionial = ({
  point,
  review,
  avatar,
  name,
  role,
}: CardTestimionialProps) => {
  const pointView = () => {
    const result = [];

    for (let i = 0; i < 5; i += 1) {
      if (i > point - 1) {
        result.push(
          <StarIconOutline width={20} key={i} className="text-amber-500" />
        );
      } else {
        result.push(
          <StarIconSolid width={20} key={i} className="text-amber-500" />
        );
      }
    }

    return result;
  };

  return (
    <div className="m-auto flex max-w-80 flex-col rounded-xl bg-white px-6 py-9 shadow-2xl">
      <div className="m-auto mb-8 flex">{pointView()}</div>
      <div className="mb-8 text-gray-600">{review}</div>
      <div className="flex items-center">
        <div className="relative mr-2 size-8 overflow-hidden rounded-full bg-gray-300">
          <Image src={avatar} alt="user" width={64} height={64} />
        </div>
        <div>
          <div className="text-sm font-semibold leading-5">{name}</div>
          <p className="text-xs leading-4 text-gray-600">{role}</p>
        </div>
      </div>
    </div>
  );
};
