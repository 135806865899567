import Image from 'next/image';

import { CheckedImage } from '@/assets';
import type { PricingPlan } from '@/config/pricingPlans';
import { currency } from '@/utils/Parsers';

interface PricingPlanProp {
  title: string;
  features: string[];
  pricingPlans: PricingPlan[];
}

export const PricingPlans = ({
  title,
  features,
  pricingPlans,
}: PricingPlanProp) => {
  return (
    <div className="flex w-full flex-col items-center justify-between gap-5 rounded-lg border border-gray-200 bg-white p-5 shadow-md xl:flex-row xl:p-10 2xl:mx-10">
      <div className="flex w-full flex-col items-center gap-5 py-2 xl:items-start">
        <h2>{title}</h2>
        <ul>
          {features.map((item, key) => (
            <li key={key} className="flex items-start gap-2 py-2 text-gray-600">
              <Image src={CheckedImage} alt="check" />
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex w-full flex-col items-center gap-3">
        {pricingPlans.map((item, key) => (
          <div
            key={key}
            className="flex w-full max-w-[440px] flex-col rounded-lg border border-gray-200 px-5 py-3 sm:px-12 sm:py-5"
          >
            <h3 className="text-lg text-primary-400">{item.name}</h3>
            <div className="flex flex-col justify-between gap-x-10 sm:flex-row sm:items-end">
              {item.original_price !== item.price && (
                <p className="text-left align-text-bottom text-gray-600">{`Tiết kiệm: ${currency(
                  +item.original_price - +item.price
                )} ₫`}</p>
              )}
              {item.original_price !== item.price && (
                <p className="text-left text-gray-600 line-through">{`Giá gốc: ${currency(
                  +item.original_price
                )} ₫`}</p>
              )}
            </div>
            <div className="mt-2 flex flex-col justify-between gap-x-10 sm:flex-row sm:items-end">
              <p className="grow text-3xl font-bold sm:text-right">{`${currency(
                +item.price
              )} ₫`}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
