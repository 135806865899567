import { motion } from 'framer-motion';
import Image from 'next/image';

import { AppStoreBtn, GgPlayBtn } from '@/assets';

export const HeroContent = () => {
  return (
    <motion.div
      className="flex flex-1 flex-col"
      initial={{ opacity: 0, translateX: -800 }}
      animate={{ opacity: 1, translateX: 0 }}
      transition={{
        duration: 0.8,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <h1 className=" text-4xl leading-tight lg:text-5xl">
        Giải pháp quản lý quán ăn miễn phí, đơn giản và hiệu quả
      </h1>
      <p className="mt-6 text-xl">
        Chào mừng bạn đến với Hàng Rong - giải pháp quản lý dành cho quán ăn vừa
        và nhỏ. Ứng dụng công nghệ hiện đại, Hàng Rong được thiết kế để đáp ứng
        mọi nhu cầu quản lý của các quán ăn mới mở và có quy mô nhỏ. Với Hàng
        Rong, việc quản lý thực đơn, đơn hàng và nhân viên trở nên đơn giản và
        thuận tiện hơn bao giờ hết.
      </p>
      <div className="mt-8">
        <button>
          <Image src={AppStoreBtn} alt="" />
        </button>
        <button className="ml-3">
          <Image src={GgPlayBtn} alt="" />
        </button>
      </div>
    </motion.div>
  );
};
