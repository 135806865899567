import Image from 'next/image';

import { AppStoreBtn, GgPlayBtn } from '@/assets';

export const SolutionsSection = () => {
  return (
    <section
      id="solutions"
      className="m-auto mt-16 flex scroll-mt-20 flex-col items-center bg-gradient-to-b from-amber-200 to-cyan-200 px-2 py-24 text-center"
    >
      <h2 className="max-w-3xl text-3xl leading-tight sm:text-5xl">
        Cùng chúng tôi sử dụng các giải pháp chuyên nghiệp dành riêng cho cửa
        hàng của bạn
      </h2>
      <p className="mt-6 text-xl text-gray-600">
        Hãy trải nghiệm ứng dụng Hàng Rong ngay hôm nay
      </p>
      <div className="mt-8">
        <button>
          <Image src={AppStoreBtn} alt="" />
        </button>
        <button className="ml-3">
          <Image src={GgPlayBtn} alt="" />
        </button>
      </div>
    </section>
  );
};
