import { motion } from 'framer-motion';
import Image from 'next/image';

import { HeroImage } from '@/assets';
import { HeroContent } from '@/components/headers/HeroContent';

export const HeroMain = () => {
  return (
    <div className="z-10 flex size-full flex-col items-center px-6 py-16 lg:flex-row lg:pl-28 xl:justify-between">
      <HeroContent />
      <motion.div
        className="mt-10 w-fit flex-1 lg:mt-0"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Image src={HeroImage} alt="" className="object-contain" />
      </motion.div>
    </div>
  );
};
