import Image from 'next/image';
import React from 'react';

import { HeroBackground } from '@/assets';
import { HeroMain } from '@/components/headers/HeroMain';

export default function HeroSection() {
  return (
    <section
      id="intro"
      className="relative z-0 mx-auto flex h-fit w-full scroll-mt-20 flex-col items-center justify-center overflow-hidden text-clip pt-20 lg:container lg:h-[700px] lg:max-w-[1440px]"
    >
      <Image
        src={HeroBackground}
        alt=""
        fill={true}
        priority={true}
        unoptimized={true}
        draggable={false}
        crossOrigin="anonymous"
        style={{ objectFit: 'cover', objectPosition: 'center' }}
        className="lg:rounded-b-3xl"
      />
      <HeroMain />
    </section>
  );
}
