import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const variants = {
  origin: {
    parent: 'lg:flex-row lg:ml-28',
    image:
      'lg:bg-gradient-to-r lg:from-primary-300 lg:via-primary-200 lg:ml-24',
    variantsContent: {
      offscreen: { x: -300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
    variantsImage: {
      offscreen: { x: 300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
  },
  reverse: {
    parent: 'lg:flex-row-reverse lg:mr-28',
    image:
      'lg:bg-gradient-to-l lg:from-primary-300 lg:via-primary-200 lg:mr-24',
    variantsContent: {
      offscreen: { x: 300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
    variantsImage: {
      offscreen: { x: -300, opacity: 0 },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          delay: 0.3,
          duration: 0.8,
        },
      },
    },
  },
};

export interface FeatureText {
  item: string;
  sublist?: string[];
}
export interface FeatureProps {
  icon: JSX.Element | React.ReactElement;
  image: JSX.Element | React.ReactElement;
  title: string;
  description: string;
  features: FeatureText[];
  variant?: keyof typeof variants;
}

export const Feature = ({
  icon,
  image,
  title,
  description,
  features,
  variant = 'origin',
  ...props
}: FeatureProps) => {
  return (
    <div
      className={`flex flex-col items-center lg:items-start lg:justify-between ${variants[variant].parent} overflow-hidden even:mt-24`}
      {...props}
    >
      <motion.div
        className="flex-1 lg:max-w-[560px]"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={variants[variant].variantsContent}
      >
        <div className="mb-6">{icon}</div>
        <h3 className="mb-4 text-3xl">{title}</h3>
        <p className="mb-8 text-lg">{description}</p>
        {features.map((feature, key) => (
          <div key={key} className="flex">
            <div className="mr-2 w-fit">
              <CheckCircleIcon className="w-6 text-primary-300" />
            </div>
            <ul className="mb-5 list-inside list-disc text-gray-600">
              <div className="mb-4">{feature.item}</div>
              {feature.sublist?.map((item, k) => (
                <li className="mb-1 ml-2" key={k}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </motion.div>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className={`flex flex-1 items-center rounded-3xl py-16 lg:px-16 ${variants[variant].image} lg:max-w-[711px]`}
        variants={variants[variant].variantsImage}
      >
        {image}
      </motion.div>
    </div>
  );
};
